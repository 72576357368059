export default () => ({
    async init(){
        import(/* webpackChunkName: "air-datepicker-styles" */ 'air-datepicker/air-datepicker.css')
        const {default: AirDatepicker} = await import(/* webpackChunkName: "air-datepicker" */ 'air-datepicker')

        new AirDatepicker(this.$root, {
            startDate: Date.now(),
            dateFormat: "dd.MM.yyyy"
        })
    }
})