export default () => ({
  async init() {
    import(/* webpackChunkName: "fancyapps-ui-styles" */ "@fancyapps/ui/dist/fancybox/fancybox.css");
    const { Fancybox } = await import(/* webpackChunkName: "fancyapps-ui" */ "@fancyapps/ui");

    Fancybox.bind(this.$root, "[data-fancybox]", {
      Html: {
        videoTpl:
          '<video class="fancybox__html5video" playsinline controls controlsList="nodownload" src="{{src}}" type="{{format}}" poster="{{poster}}"></video>',
      },
    });
  },
});
