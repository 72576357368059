export default () => ({
  async init() {
    const { default: Swiper } = await import(/* webpackChunkName: "swiper" */ "../libs/Swiper");

    const thumbsSwiper = new Swiper(this.$refs.thumbsSwiper, {
      speed: 900,
      spaceBetween: 13,
      slidesPerView: 7,
      breakpoints: {
        1280: {
          slidesPerView: 6,
        },
      },
    });

    const mainSwiper = new Swiper(this.$refs.mainSwiper, {
      speed: 900,
      spaceBetween: 20,
      navigation: {
        prevEl: this.$refs.prev,
        nextEl: this.$refs.next,
      },
      pagination: {
        el: this.$refs.pagination,
      },
      thumbs: {
        swiper: thumbsSwiper,
      },
    });
  },
});
