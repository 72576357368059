export default () => ({
    async init() {
        const { default: Swiper } = await import(/* webpackChunkName: "swiper" */ "../libs/Swiper");
        new Swiper(this.$root, {
            speed: 500,
            nested: true,
            pagination: {
                el: this.$refs.pagination,
                clickable: true
            }
        });
    },
})