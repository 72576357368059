import moduleRequest from "../utils/moduleRequest"

export default (productID, isAdded) => ({
    isAdded,
    button: {
        [':class']: "isAdded && 'active'",
        ['@click']() {
            const body = new FormData()
            body.append("sessid", this.$sessid)
            body.append("id", productID)

            moduleRequest("placestart:wishlist", "api.WishlistApi.toggle", {
                method: 'POST',
                body
            }).then(result => {
                this.isAdded = result.data.IN_WISHLIST

                if (result.data.IN_WISHLIST) {
                    this.$store.wishlist.count += 1
                }
                else {
                    this.$store.wishlist.count -= 1
                }
            })
        }
    }
})
