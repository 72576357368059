import componentRequest from "../utils/componentRequest"

const courierDeliveryID = 11

export default ({ deliveryID = null }) => ({
    locationSearch: null,
    deliveryBlock: null,
    currentDelivery: "courier",
    deliveryInfo: {},
    get deliveryInfoShow() {
        if (this.deliveryInfo.mode == "office") {
            return `
                Доставка в пункт выдачи
                Название пункта: ${this.deliveryInfo.name}
                Тип пункта: ${this.deliveryInfo.type == "PVZ" ? "ПВЗ" : "Постамат"}
                Код пункта: ${this.deliveryInfo.code}
                Город: ${this.deliveryInfo.city}
                Адрес пункта: ${this.deliveryInfo.address}
                Почтовый индекс: ${this.deliveryInfo.postal_code}
            `
        }
        else if (this.deliveryInfo.mode == "door") {
            return `
                Доставка до двери
                Адрес: ${this.deliveryInfo.address}
                Город: ${this.deliveryInfo.city}
                Почтовый индекс: ${this.deliveryInfo.postal_code}
                Координаты на карте: ${this.deliveryInfo.position}
            `
        }
    },
    init() {
        this.switchDelivery(deliveryID)
        this.locationSearch = $(this.$refs.locationSearch)
        this.deliveryBlock = $(this.$refs.deliveryBlock)

        this.initLocation()
        this.initSdek()
    },
    initLocation() {
        this.locationSearch.selectize({
            valueField: 'code',
            labelField: 'label',
            searchField: 'label',
            create: false,
            render: {
                option: function (item, escape) {
                    return '<div class="title">' + escape(item.label) + '</div>';
                }
            },
            load: async (q, callback) => {
                if (!q.length) return callback();

                const data = new FormData
                data.append("q", q)
                const result = await componentRequest("placestart:opensource.order", "searchLocation", {
                    body: data,
                    method: "POST"
                })
                callback(result.data)
            }
        });

        // this.locationSearch.change((event) => this.onLocationSearchChange(event))
    },
    initSdek() {
        new window.CDEKWidget({
            from: 'Москва',
            root: this.$refs.sdekWidgetRoot.id,
            apiKey: 'a85a37e9-c099-447b-a25c-7042e82a70d4',
            servicePath: '/local/components/placestart/opensource.order/service.php',
            defaultLocation: 'Москва',
            onChoose: (mode, tariff, address) => {
                console.log(mode, tariff, address)
                if (mode == "office") {
                    this.deliveryInfo = {
                        mode,
                        name: address.name,
                        type: address.type,
                        code: address.code,
                        city: address.city,
                        address: address.address,
                        postal_code: address.postal_code,
                    }
                }
                else if (mode == "door") {
                    this.deliveryInfo = {
                        mode,
                        city: address.city,
                        address: address.formatted,
                        postal_code: address.postal_code,
                        position: address.position,
                    }
                }
            }
        });
    },
    switchDelivery(deliveryID) {
        if (deliveryID == courierDeliveryID)
            this.currentDelivery = "courier"
        else
            this.currentDelivery = "cdek"
    },
    onDeliveryChange(event) {
        this.switchDelivery(parseInt(event.target.value))
    },
    async onLocationSearchChange(event) {
        const locationCode = event.target.value;

        if (locationCode.length == 0)
            return

        const result = await componentRequest("placestart:opensource.order", "calculateDeliveries", {
            body: new FormData(this.$refs.orderForm),
            method: "POST"
        })

        const selectedDelivery = this.deliveryBlock.find('input:checked');
        const selectedDeliveryId = selectedDelivery.length > 0 ? selectedDelivery.val() : 0;

        this.deliveryBlock.html('');
        $.each(result.data, (i, deliveryData) => {
            this.renderDelivery(deliveryData);
        });

        this.switchDelivery(selectedDeliveryId)
        if (selectedDeliveryId > 0) {
            this.deliveryBlock.find('label.delivery' + selectedDeliveryId + ' input').prop('checked', true);
        }
    },
    deliveryTemplate(deliveryData) {
        return `
        <label class="type t-12 delivery${deliveryData.id}">
            <input type="radio" name="delivery_id" class="delivery-input" value="${deliveryData.id}" checked="">
            ${deliveryData.name}
        </label>
        `
    },
    renderDelivery(deliveryData) {
        this.deliveryBlock.append(this.deliveryTemplate(deliveryData));
    },
    refreshTotalInfo() {
        this.$dispatch("refresh-total")
    },
    async deleteCoupon(coupon) {
        const body = new FormData()
        body.append("coupon", coupon)
        const result = await componentRequest("placestart:opensource.order", "deleteCoupon", {
            method: "POST",
            body
        })
        if (result.status == "success")
            this.refreshTotalInfo()
    },
    async applyCoupon(coupon) {
        const body = new FormData()
        body.append("coupon", coupon)
        const result = await componentRequest("placestart:opensource.order", "applyCoupon", {
            method: "POST",
            body
        })
        if (result.status == "success")
            this.refreshTotalInfo()
    }
})
