export default () => ({
    async init() {
        const { default: Swiper } = await import(/* webpackChunkName: "swiper" */ "../libs/Swiper");

        new Swiper(this.$refs.swiper, {
            slidesPerView: 1,
            speed: 900,
            spaceBetween: 20,
            slideToClickedSlide: true,
            navigation: {
                prevEl: this.$refs.prev,
                nextEl: this.$refs.next,
            },
            pagination: {
                el: this.$refs.pagination,
                clickable: true
            },
            breakpoints: {
                1280: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 1
                },
            }
        });
    },
});
