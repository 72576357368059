import '@fontsource-variable/jost';
import "./main.scss";
import "swiper/css";

window.addEventListener("load", () => {
    import(/* webpackChunkName: "lazy-styles" */ "./lazy-styles.scss")
    import("htmx.org").then(htmx => {
        htmx.config.scrollIntoViewOnBoost = false
    })
})

import "./alpine";
