export default ({
    ajaxURL = "",
    orderBy = "",
    order = "",
    params = {}
}) => ({
    applyFilterURL: params.SEF_SET_FILTER_URL,
    clearFilterURL: params.SEF_DEL_FILTER_URL,
    formAction: ajaxURL,
    buttonAction: "set_filter",
    orderBy: orderBy,
    order: order,
    isRequest: false,
    fetchController: null,
    form: null,
    init() {
        this.form = this.$root.querySelector("[x-ref=form]")
        this.$watch('order', () => {
            this.dispatchSubmit()
        })
    },
    onChange() {
        this.update()
    },
    update() {
        if (this.fetchController) {
            this.fetchController.abort()
        }

        this.isRequest = true
        const formData = new FormData(this.$refs.form)
        formData.append("ajax", "y")

        this.fetchController = new AbortController();

        fetch(ajaxURL, {
            method: 'POST',
            body: formData,
            signal: this.fetchController.signal
        })
            .then(result => result.json()).then(result => {
                this.applyFilterURL = result.SEF_SET_FILTER_URL
                this.clearFilterURL = result.SEF_DEL_FILTER_URL
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => this.isRequest = false)
    },
    checkboxChange(name, text) {
        if (this.$el.checked) {
            const button = `
            <button class="tag" @click="tagClick" name="${name}">
                <span class="tag-name">${text}</span>
                <svg class="icon">
                    <use href='${window.spritePath}#cross'></use>
                </svg>
            </button>
            `
            this.$refs.tags.insertAdjacentHTML("beforeend", button)
        }
        else {
            this.$refs.tags.querySelector(`button[name="${name}"]`).remove()
        }
    },
    dispatchSubmit() {
        this.$nextTick(() => {
            this.$dispatch("form-submit")
        })
    },
    applyFilter() {
        this.buttonAction = "set_filter"
        this.formAction = this.applyFilterURL
        this.dispatchSubmit()
    },
    clearFilter() {
        this.buttonAction = "del_filter"
        this.formAction = this.clearFilterURL
        this.$refs.tags.innerHTML = ""
        this.dispatchSubmit()
    },
    tagClick() {
        const checkbox = this.form.querySelector(`[name="${this.$el.name}"]`)
        checkbox.checked = false
        this.$el.remove()
    }
})
