import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";

Alpine.plugin(intersect);

Alpine.magic("sessid", () => {
    return window.sessid
})

Alpine.magic("templatePath", () => {
    return window.templatePath
})

Alpine.magic("spritePath", () => {
    return window.spritePath
})

import ContactsMap from "./ContactsMap";
Alpine.data("ContactsMap", ContactsMap);

import PhoneInputMask from "./PhoneInputMask";
Alpine.data("PhoneInputMask", PhoneInputMask);

import SimpleSlider from "./SimpleSlider";
Alpine.data("SimpleSlider", SimpleSlider);

import FancyboxGallery from "./FancyboxGallery";
Alpine.data("FancyboxGallery", FancyboxGallery);

import ProductCardSlider from "./ProductCardSlider";
Alpine.data("ProductCardSlider", ProductCardSlider);

import ProductSlider from "./ProductSlider";
Alpine.data("ProductSlider", ProductSlider);

import OverlayMenu from "../components/OverlayMenu"
Alpine.data("OverlayMenu", OverlayMenu)

import Accordion from "../components/Accordion";
Alpine.data("Accordion", Accordion);

import DropdownList from "../components/DropdownList"
Alpine.data("DropdownList", DropdownList)

import ModalHelper from "../components/Modal";
Alpine.data("ModalHelper", ModalHelper);

import RangeInput from "./RangeInput"
Alpine.data("RangeInput", RangeInput)

import CatalogFilters from "./CatalogFilters"
Alpine.data("CatalogFilters", CatalogFilters)

import DateInputMask from "./DateInputMask"
Alpine.data("DateInputMask", DateInputMask)

import WishlistButton from "./WishlistButton"
Alpine.data("WishlistButton", WishlistButton)

import ActionsSlider from "./ActionsSlider"
Alpine.data("ActionsSlider", ActionsSlider)

import Order from "./Order"
Alpine.data("Order", Order)

import ToBasketButton from "./ToBasketButton"
Alpine.data("ToBasketButton", ToBasketButton)

import CalendarInput from "./CalendarInput"
Alpine.data("CalendarInput", CalendarInput)

import CookiesMessage from "./CookiesMessage"
Alpine.data("CookiesMessage", CookiesMessage)

Alpine.store("mobileMenu", {
    open: false,
});

Alpine.store("catalogMenu", {
    open: false,
});

Alpine.store("customersMenu", {
    open: false,
});

Alpine.store("header", {
    isFixed: false,
});

Alpine.store("wishlist", {
    count: parseInt(window.wishlistCount)
})

Alpine.store("cart", {
    count: parseInt(window.cartCount)
})

Alpine.start();
