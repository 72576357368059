import ky from 'ky';

export default (ID, isAdded) => ({
    isAdded,
    button: {
        [':class']: "isAdded && 'active'",
        ['@click']() {
            const body = new FormData
            body.append("ajax_basket", "Y")

            ky(`?action=ADD2BASKET&id=${ID}`, {
                method: "POST",
                body
            }).json()
                .then(result => {
                    console.log(result)
                })
        }
    }
})
