export default ({
    speed = 900,
    spaceBetween = 0,
    desktopSlides = 1,
    mobileSlides = 1,
    tabletSlides = 1,
    autoHeight = false,
    slideToClickedSlide = false
}) => ({
    async init() {
        const { default: Swiper } = await import(/* webpackChunkName: "swiper" */ "../libs/Swiper");
        new Swiper(this.$refs.swiper, {
            slidesPerView: mobileSlides,
            speed,
            spaceBetween,
            autoHeight,
            slideToClickedSlide,
            navigation: {
                prevEl: this.$refs.prev,
                nextEl: this.$refs.next,
            },
            pagination: {
                el: this.$refs.pagination,
                clickable: true
            },
            breakpoints: {
                1280: {
                    slidesPerView: desktopSlides
                },
                576: {
                    slidesPerView: tabletSlides
                },
            }
        });
    },
});
